import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import {
  BehaviorSubject,
  catchError,
  filter,
  firstValueFrom,
  map,
  of,
  switchMap,
} from 'rxjs';
import {
  IAuthNewRefreshResponse,
  IUserCredentials,
  IAuthNewUserData,
  IAuthPollTokenWithDeviceCodeResponse,
  IAuthRequestDeviceCodeResponse,
  IAuthSignUpAnonymousResponse,
} from '../../../models/auth.interface';
import { LoaderService } from '../loader/loader.service';
import { TelegramService } from '../telegram/telegram.service';

const LKS_ID_TOKEN = 'idToken';
const LSK_REFRESH_TOKEN = 'refreshToken';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private userCredentials = new BehaviorSubject<IUserCredentials | null>(null);
  private userData = new BehaviorSubject<IAuthNewUserData | null>(null);
  private isAnonymousSignedIn = new BehaviorSubject<boolean>(false);
  private deviceCode: string | null = null;
  private deviceCodeExpireAt: number | null = null;
  private isCheckingDeviceCode: boolean = false;

  userCredentials$ = this.userCredentials.asObservable();
  userData$ = this.userData.asObservable();
  isAuthenticated$ = this.userCredentials.pipe(map((data) => !!data));
  isAnonymousSignedIn$ = this.isAnonymousSignedIn.asObservable();

  constructor(
    private readonly _http: HttpClient,
    private readonly _telegramService: TelegramService,
    private readonly _loaderService: LoaderService
  ) {}

  async init() {
    let init = true;
    this.userCredentials
      .pipe(
        filter(() => !init),
        switchMap((data) => {
          if (data) {
            if (!data.email) {
              this.isAnonymousSignedIn.next(true);
            }
            return this.loadUserProfile();
          } else {
            localStorage.removeItem(LKS_ID_TOKEN);
            localStorage.removeItem(LSK_REFRESH_TOKEN);
            this.userData.next(null);
            return of(null);
          }
        })
      )
      .subscribe((userData) => {
        if (userData) {
          this.userData.next(userData);
        } else {
          this.userData.next({
            displayName: '',
            email: '',
            shortUID: null,
            userId: '',
            profilePicture: null,
          } as IAuthNewUserData);
        }
      });
    init = false;

    this.setupDeviceCodeListener();
    this.startSessionHealthCheck();

    const idTokenInStorage = localStorage.getItem(LKS_ID_TOKEN);
    const refreshTokenInStorage = localStorage.getItem(LSK_REFRESH_TOKEN);

    if (idTokenInStorage && refreshTokenInStorage) {
      this.setUserCredentials(idTokenInStorage, refreshTokenInStorage, false);
      this.refreshToken();
    } else {
      this.signOut();
    }
  }

  private startSessionHealthCheck(): void {
    setInterval(() => {
      const userIdInMemory = this.userCredentials.value?.userId ?? "";
      let userIdInStorage = "";

      const idTokenInStorage = localStorage.getItem(LKS_ID_TOKEN);
      const refreshTokenInStorage = localStorage.getItem(LSK_REFRESH_TOKEN);

      if (idTokenInStorage) {
        const decodedIdToken = this.decodeJwt(idTokenInStorage);
        userIdInStorage = decodedIdToken?.user_id ?? "";
      }

      if (userIdInMemory !== userIdInStorage) {
        this.setUserCredentials(idTokenInStorage, refreshTokenInStorage, false);
      }
    }, 1000);
  }

  private setupDeviceCodeListener(): void {
    this.isAuthenticated$.subscribe((isAuthenticated) => {
      if (!isAuthenticated) {
        this.checkAndRequestDeviceCode(true);
      }
    });
  
    const deviceCodeCheckInterval = 1000 * 60 * 1;
    setInterval(() => {
      if (!this.isCheckingDeviceCode) {
        this.checkAndRequestDeviceCode(false);
      }
    }, deviceCodeCheckInterval);
  }

  private loadUserProfile() {
    const url = `${environment.apiUrl}/user-getProfile`;
    const data = {
      version: 2,
      appId: environment.projectId,
    };
    return this._http.post<IAuthNewUserData>(url, data).pipe(
      map((userData) => {
        return userData;
      }),
      catchError((err) => {
        return of(null);
      })
    );
  }

  public runOAuth() {
    if (!this.deviceCode) {
      return;
    }
    const deviceCode = this.deviceCode;
    const url = `${environment.auth.url}?` +
      'returnSecureToken=true&' +
      'returnRefreshToken=true&' +
      `appId=${environment.projectId}&` +
      `device_id=${deviceCode}`;
    this._telegramService.openLink(url);
    this._loaderService.show();
  
    const intervalMs = 5000;
    const intervalId = setInterval(async () => {
      try {
        const pollResponse = await this.pollTokenWithDeviceCode(deviceCode);
        if (pollResponse.status === 'completed') {
          await this.refreshToken(pollResponse.token);
          this._loaderService.hide();
          clearInterval(intervalId);
        } else if (pollResponse.status === 'expired') {
          console.error('Error polling token: Device code expired');
          this._loaderService.hide();
          clearInterval(intervalId);
        }
      } catch (error) {
        console.error('Error polling token:', error);
        this._loaderService.hide();
        clearInterval(intervalId);
      }
    }, intervalMs); 
  }

  public async signUpAnonymously(): Promise<void> {
    this.isAnonymousSignedIn.next(false);

    try {
      const url = `${environment.apiUrl}/user-signUpAnonymously`;
      const data = { appId: environment.projectId };
      const response: IAuthSignUpAnonymousResponse = await firstValueFrom(
        this._http.post<any>(url, data)
      );
      this.setUserCredentials(
        response.idToken,
        response.refreshToken,
        true
      );
    } catch (error) {
      console.error('Anonymous sign-in failed:', error);
    } finally {
      this.isAnonymousSignedIn.next(true);
    }
  }

  public async refreshToken(refreshToken?: string | null): Promise<string | null> {
    if (!refreshToken) {
      const refreshTokenInMemory = this.userCredentials.value?.refreshToken;
      if (!refreshTokenInMemory) {
        return null;
      }
      refreshToken = refreshTokenInMemory;
    }

    const data = { refreshToken };
    const url = `${environment.apiUrl}/user-refreshTokens`;

    try {
      const response: IAuthNewRefreshResponse = await firstValueFrom(
        this._http.post<any>(url, data)
      );

      this.setUserCredentials(
        response.idToken,
        response.refreshToken,
        true
      );

      return response.idToken;
    } catch (err) {
      this.signOut();
      return null;
    }
  }

  public signOut(): void {
    this.userCredentials.next(null);
    this.userData.next(null);
    localStorage.removeItem(LKS_ID_TOKEN);
    localStorage.removeItem(LSK_REFRESH_TOKEN);
    this.signUpAnonymously();
  }

  private requestOAuthDeviceCode(): Promise<IAuthRequestDeviceCodeResponse> {
    const url = `${environment.apiUrl}/user-device-requestAuthentication`;
    return firstValueFrom(
      this._http.post<any>(url, {})
    ).then(response => {
      const deviceCode = response.deviceId;
      const expireAt = response.expireAt;
      return { deviceCode, expireAt } as IAuthRequestDeviceCodeResponse;
    });
  }

  private pollTokenWithDeviceCode(deviceCode: string): Promise<IAuthPollTokenWithDeviceCodeResponse> {
    const url = `${environment.apiUrl}/user-device-token`;
    const data = { deviceId: deviceCode };
    return firstValueFrom(
      this._http.post<any>(url, data)
    ).then(response => {
      return response as IAuthPollTokenWithDeviceCodeResponse;
    });
  }

  private checkAndRequestDeviceCode(force: boolean): void {
    if (this.isAuthenticatedWithEmail()) {
      return;
    }
    if (this.isDeviceCodeExpired() || force) {
      this.isCheckingDeviceCode = true;
      this.requestOAuthDeviceCode()
        .then((response) => {
          this.deviceCode = response.deviceCode;
          this.deviceCodeExpireAt = response.expireAt;
        })
        .catch((error) => {
          console.error('Error requesting device code:', error);
        })
        .finally(() => {
          this.isCheckingDeviceCode = false;
        });
    }
  }

  private isDeviceCodeExpired(): boolean {
    return this.deviceCode === null ||
            this.deviceCodeExpireAt === null ||
            this.deviceCodeExpireAt < Date.now();
  }

  public isAuthenticated(): boolean {
    return !!this.userCredentials.value;
  }

  public isAuthenticatedWithEmail(): boolean {
    return this.isAuthenticated() && !!this.userCredentials.value?.email;
  }

  public getUserIdToken(): string | null {
    return !this.userCredentials.value
      ? null
      : this.userCredentials.value.idToken;
  }

  public getUserRefreshToken(): string | null {
    return !this.userCredentials.value
      ? null
      : this.userCredentials.value.refreshToken;
  }

  public setUserCredentials(
    idToken: string | null,
    refreshToken: string | null,
    remember: boolean
  ): void {
    if (idToken && refreshToken) {
      const decodedIdToken = this.decodeJwt(idToken);
      const userId: string = decodedIdToken?.user_id;
      const email: string | undefined = this.decodeJwt(idToken)?.email;
      this.userCredentials.next({ idToken, refreshToken, userId, email });
  
      if (remember) {
        localStorage.setItem(LKS_ID_TOKEN, idToken);
        localStorage.setItem(LSK_REFRESH_TOKEN, refreshToken);
      }
    } else if (!idToken && refreshToken) {
      this.refreshToken(refreshToken);
    } else {
      this.signOut();
    }
  }

  public isJwtTokenExpired(token: string): boolean {
    const decodedToken = this.decodeJwt(token);
    if (!decodedToken) {
      return true;
    }
    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    return decodedToken.exp < currentTime;
  }

  private decodeJwt(token: string): any {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
          .join('')
      );

      return JSON.parse(jsonPayload);
    } catch (error) {
      console.error('Error decoding token:', error);
      return null;
    }
  }

  public mapError(error: 'INVALID_EMAIL' | 'EMAIL_NOT_FOUND') {
    const defaultError = {
      message: 'Something went wrong, please try again later',
      type: 'error',
    };

    const errorMap: Record<string, { message: string; type: string }> = {
      INVALID_EMAIL: {
        message: 'E-mail not found',
        type: 'accent',
      },
      EMAIL_NOT_FOUND: {
        message: 'E-mail not found',
        type: 'accent',
      },
      INVALID_PASSWORD: {
        message: "Password doesn't match",
        type: 'accent',
      },
      INVALID_ID_TOKEN: {
        message: 'Token is invalid, try the signin process again',
        type: 'error',
      },
      EMAIL_EXISTS: {
        message: 'This e-mail already exists',
        type: 'error',
      },
      TOO_MANY_ATTEMPTS_TRY_LATER: {
        message: "You've tried too many times, try again later",
        type: 'error',
      },
    };

    return Object.prototype.hasOwnProperty.call(errorMap, error)
      ? errorMap[error]
      : defaultError;
  }
}
